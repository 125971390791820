<template>
  <div class="page">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="出售中">
        <product-list :is_active="true"></product-list>
      </a-tab-pane>
      <a-tab-pane key="2" tab="仓库中" force-render>
        <product-list :is_active="false"></product-list>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
  export default {
    name: 'GoodsList',
    components: {
      productList: () => import('./list')
    },
    data() {
      return {
      };
    },
    methods: {
    },
  }
</script>

<style scoped>
</style>